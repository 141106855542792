import {
  colorSteelMedium,
  fontFamilyBase,
  sizeMedium,
  sizeXsmall,
  sizeXxsmall,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";
import { connectToggleRefinement } from "react-instantsearch-dom";

import Switch from "../Switch";
import Text from "../Text";

interface Props {
  count: {
    checked: number;
    unchecked: number;
  };
  currentRefinement: boolean;
  label: string;
  refine: (currentRefinement: boolean) => void;
}

const ToggleSelectedPublications: FunctionComponent<Props> = ({
  count,
  currentRefinement,
  label,
  refine,
}) => {
  const { unchecked } = count;

  // Sometimes currentValue is a string containing
  // "true" or "false"-- not the expected Boolean:
  // https://www.algolia.com/doc/api-reference/widgets/toggle-refinement/react/#connector-param-provided-currentrefinement
  // Let's use JSON.parse to ensure a Boolean:
  const checked = JSON.parse(currentRefinement.toString());

  // The Algolia docs say to pass !currentRefinement:
  // https://www.algolia.com/doc/api-reference/widgets/toggle-refinement/react/#connector
  // Yet TS complains, saying it was expecting 0 arguments. So,

  const handleClick = () => refine(!checked);

  return (
    <div
      css={css`
        align-items: center;
        display: flex;
        gap: ${sizeXsmall};
        font-family: ${fontFamilyBase};
        font-size: ${sizeMedium};
      `}
      title={
        "We love keeping up with the latest journal articles and curated this list to include hot topics, demonstrations of 10x technology, and more. We'll continuously review articles and add them to this list."
      }
    >
      <Switch checked={checked} label={label} onClick={handleClick} />

      <label
        css={css`
          border-bottom: 1px dashed ${colorSteelMedium};
          padding-bottom: ${sizeXxsmall};
          white-space: nowrap;
        `}
      >
        <Text
          as={"span"}
          css={css`
            font-weight: ${checked ? "bold" : "normal"};
          `}
          size={"small"}
        >
          {label}
        </Text>

        {unchecked !== null ? (
          <>
            &nbsp;
            <span
              css={css`
                color: ${colorSteelMedium};
              `}
            >{`(${unchecked || 0})`}</span>
          </>
        ) : null}
      </label>
    </div>
  );
};

export default connectToggleRefinement(ToggleSelectedPublications);
